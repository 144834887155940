.user-simple-profile {
  display: flex;
  justify-content: flex-end;
}
.navbar-div {
  background: #000000;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-email {
  color: black;
  margin-left: 15px;
  font-size: 12px;
}
.user-logout {
  color: red;
  margin-left: 15px;
  cursor: pointer;
}
.user-balance-show {
  font-size: 14px;
  color: #ffffff;
}
.user-wallet {
  display: flex;
  justify-content: space-between;
  margin: 15px 15px 0px 15px;
  color: black;
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #e3dbe4;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 8%);
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 10px;
  text-decoration: none;
  font-size: 14px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: -15px;
  right: 0px;
  font-size: 36px;
  margin-left: 50px;
  color: #cdc6eb;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

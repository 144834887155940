.table-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.form-control {
  font-size: 14px;
}
.form-label {
  font-size: 14px;
}
.modal-content {
  margin: 8%;
}
.table tr {
  font-size: 14px;
}
.user-data-tabel {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 64px;
  overflow: hidden;
  -webkit-transition: all 0.15s;
  transition: all 0.15s;
  padding: 0 20px;
  background: inherit;
  -webkit-transition: background-color 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: background-color 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}
table {
  border: 1px solid black;
  overflow-x: scroll;
  max-height: 350px;
}

td,
th {
  border: 1px solid black;
  width: 1%;
}

.table-fixed thead {
  width: 97%;
}

.table-fixed tbody {
  height: 150px;
  overflow-y: scroll;
  width: 100%;
}
.status-form-div {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.add-user-check {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.state-array {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  transition: 5s all ease;
  display: flex;
  gap: 1px;
  padding: 15px 15px 15px 15px;
}
.state-button-user {
  background: #cdc6eb;
  color: #0000008a;
  border: 1px solid white;
  padding: 8px;
  height: 44px;
  border-radius: 5px;
}
.state-button-user-select {
  border: 3px solid black;
  background: #cdc6eb;
  color: #0000008a;

  padding: 8px;
  height: 44px;
  border-radius: 5px;
}
.bet-live-button-div{
  display: flex;
    align-items: center;
    gap: 40px;
    margin: 12px 0px 13px 15px;
}
.bet-live-button{
  display: flex;
  align-items: center;
  gap: 11px;
  border: 2px solid rgb(194 185 185);
    background: #d32f2f!important;
}
.bet-live-video{
  max-height: 200px;
    margin: 11px 6px;
}
.bet-live-video {
  position: relative;
  width: 100%;
  height: 200px;
}

.bet-live-video iframe {
  width: 100%;
  height: 200px;
  border: none;
  display: block;
}

.bet-live-videovideo {
  width: 100%;
  height: 200px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}